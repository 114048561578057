/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState } from "react"
import Layout from "../components/layout"
import SoundByte from "../components/sound-byte"

import UIfx from "uifx"
import correctMp3 from "../sounds/correct.mp3"
import wrongMp3 from "../sounds/wrong.mp3"

const SoundsPage = () => {
  const [correctFx, setCorrectFx] = useState()
  const [wrongFx, setWrongFx] = useState()
  useEffect(() => {
    setCorrectFx(new UIfx(correctMp3))
    setWrongFx(new UIfx(wrongMp3))
  }, [])

  return (
    <Layout>
      <div
        sx={{
          display: "grid",
          gridGap: 3,
        }}
      >
        <SoundByte sound={correctFx} name="Correct!" />
        <SoundByte sound={wrongFx} name="Wrong!" variant="secondary" />
      </div>
    </Layout>
  )
}

export default SoundsPage
