/** @jsx jsx */
import { jsx } from "theme-ui"
import Button from "./button"

const SoundByte = ({ sound, name, variant = "primary" }) => (
  <Button
    onClick={() => sound.play(0.7)}
    variant={variant}
    sx={{ height: "40vh" }}
  >
    {name}
  </Button>
)

export default SoundByte
